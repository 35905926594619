// This is where you dynamically change information for an identical site with a different domain.
// Don't forget to import any images you may need

import dynamicSiteConfig from '../../../../src/utils/dynamic/dynamicSiteConfig';

/**
 * inserts newNode after defined referenceNode
 * @param  {object} referenceNode
 * @param  {object} newNode
 */
const insertAfter = (referenceNode, newNode) => {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
};

/**
 *
 * @param {*} config - config object. Use this to alter any config options you need to
 * @param {*} setDocumentTitle - function. Needed to implicitly change the document title
 * @param {*} query - params object.
 */

const customConfig = (config, setDocumentTitle, query) => {
  /**
   * updates main config from dynamicSites config
   * @param  {object} config
   */

  dynamicSiteConfig(config);

  const url = document.URL;
  const agParam = query.ag;
  const banner = document.getElementById('autogo2-custom-banner');
  const logoContainer = document.getElementById('autogo2-custom-logos');
  const header = document.getElementById('header');
  // first question change..
  // styling changes for this variation

  // change the styles for first question
  if (agParam === '1') {
    const mainApp = document.getElementsByClassName('main-app-page-0')[0];
    const formContainer = document.getElementsByClassName('initial')[0];
    mainApp.classList.remove('main-app-page-0');
    formContainer.classList.remove('initial');
    banner.style.display = 'none';
    logoContainer.style.display = 'none';
  }

  /**
   * DYNAMIC SITES
   * @param  {string} url.includes()
   */
  // These sites are on CF d6199tsqdwkmu.cloudfront.net
  if (
    url.includes('autogo2.insurancespecialists') ||
    (url.includes('localhost') && typeof window !== 'undefined')
  ) {
    if (!window.runUpdates) {
      const pollWin = setInterval(() => {
        const mainApp = document.getElementById('main-app');
        if (mainApp) {
          mainApp.classList.add('showLogos');
          clearInterval(pollWin);
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(pollWin);
      }, 10000);
      window.runUpdates = true;
    }
  }

  if (url.includes('autogosavings.com')) {
    const headline = document.getElementById('headline');
    if (header && headline) {
      header.classList.add('margin6', 'mWidth265');
      headline.classList.add('keepHeadLine');
    }
  }

  if (url.includes('autorateselect.com')) {
    if (header) {
      header.classList.add('margin6');
    }
  }
};

export default customConfig;
